// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.home_HomeContainer__RjDaP {
    border-radius: 25px 25px 0 0;
    background: #F4F4F4;
    overflow: hidden;
    padding-bottom: 100px;
}

.home_AdsItems__Z9gqw {
    margin: 0 15px 80px 15px;
    padding: 10px 0;
}

.home_HomeCategories__FRMU5 {
    white-space: nowrap;
    flex-wrap: nowrap;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    margin: 0;
    padding: 10px 15px;
    background: #F4F4F4;
    position: relative;
}

.home_HomeCategories__FRMU5 li {
    width: 100%;
    background: hsla(0, 0%, 98%, .85);
    border-radius: 15px;
    border: 1px solid #fff;
    padding: 10px;
    overflow: unset;
    margin: 0 3px
}

.home_HomeCategories__FRMU5 li a {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
}

.home_HomeCategories__FRMU5 li i {
    font-size: 30px;
    display: flex;
}

.home_HomeCategories__FRMU5 li span {
    display: block;
    width: 100%;
    font-size: 13px;
    text-align: center;
}`, "",{"version":3,"sources":["webpack://./src/assets/styles/home.module.css"],"names":[],"mappings":"AAAA;IACI,4BAA4B;IAC5B,mBAAmB;IACnB,gBAAgB;IAChB,qBAAqB;AACzB;;AAEA;IACI,wBAAwB;IACxB,eAAe;AACnB;;AAEA;IACI,mBAAmB;IACnB,iBAAiB;IACjB,gBAAgB;IAChB,iCAAiC;IACjC,SAAS;IACT,kBAAkB;IAClB,mBAAmB;IACnB,kBAAkB;AACtB;;AAEA;IACI,WAAW;IACX,iCAAiC;IACjC,mBAAmB;IACnB,sBAAsB;IACtB,aAAa;IACb,eAAe;IACf;AACJ;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,eAAe;AACnB;;AAEA;IACI,eAAe;IACf,aAAa;AACjB;;AAEA;IACI,cAAc;IACd,WAAW;IACX,eAAe;IACf,kBAAkB;AACtB","sourcesContent":[".HomeContainer {\r\n    border-radius: 25px 25px 0 0;\r\n    background: #F4F4F4;\r\n    overflow: hidden;\r\n    padding-bottom: 100px;\r\n}\r\n\r\n.AdsItems {\r\n    margin: 0 15px 80px 15px;\r\n    padding: 10px 0;\r\n}\r\n\r\n.HomeCategories {\r\n    white-space: nowrap;\r\n    flex-wrap: nowrap;\r\n    overflow-x: auto;\r\n    -webkit-overflow-scrolling: touch;\r\n    margin: 0;\r\n    padding: 10px 15px;\r\n    background: #F4F4F4;\r\n    position: relative;\r\n}\r\n\r\n.HomeCategories li {\r\n    width: 100%;\r\n    background: hsla(0, 0%, 98%, .85);\r\n    border-radius: 15px;\r\n    border: 1px solid #fff;\r\n    padding: 10px;\r\n    overflow: unset;\r\n    margin: 0 3px\r\n}\r\n\r\n.HomeCategories li a {\r\n    display: flex;\r\n    align-items: center;\r\n    justify-content: center;\r\n    flex-wrap: wrap;\r\n}\r\n\r\n.HomeCategories li i {\r\n    font-size: 30px;\r\n    display: flex;\r\n}\r\n\r\n.HomeCategories li span {\r\n    display: block;\r\n    width: 100%;\r\n    font-size: 13px;\r\n    text-align: center;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"HomeContainer": `home_HomeContainer__RjDaP`,
	"AdsItems": `home_AdsItems__Z9gqw`,
	"HomeCategories": `home_HomeCategories__FRMU5`
};
export default ___CSS_LOADER_EXPORT___;
