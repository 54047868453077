import Style from "../assets/styles/browse.module.css";
import {Button, Dropdown, Radio, Select, Slider, Tag} from "antd";
import {useEffect, useState} from "react";
import {useParams, useSearchParams} from "react-router-dom";
import {getBrandsApi, getCityByProvinceNameApi, getDistrictByIdApi, getModelApi} from "../api/api";
import {AdsCarColor, AdsCarYear, AdsEstateOptions, unDasher} from "../api/helper";
import {useSelector} from "react-redux";

const Filter = ({categories, subCategories, subSubCategories, category, subcat, subsubcat}) => {

    const Params = useParams();
    const [status, setStatus] = useState(false);
    const [filterData, setFilterData] = useState({});
    const [filters, setFilters] = useState({});
    const [searchParams, setSearchParams] = useSearchParams();
    const [cities, setCities] = useState([]);
    const [districts, setDistricts] = useState([]);
    const [brands, setBrands] = useState([]);
    const [models, setModels] = useState([]);
    const [brandsLoading, setBrandsLoading] = useState(false);
    const [modelsLoading, setModelsLoading] = useState(false);

    const category_id = category ? categories?.find((x)=>x.name == unDasher(category))?.id : null;
    const subcategory_id = subcat ? subCategories?.find((x)=>x.name == unDasher(subcat))?.id : null;
    const subsubcat_id = subsubcat ? subSubCategories?.find((x)=>x.name == unDasher(subsubcat))?.id : null;

    const selectedProvince = useSelector((state)=>state?.user?.province) || "ایران";

    useEffect(()=>{
        if(subsubcat){
            getBrands();
        }
    },[subsubcat]);

    useEffect(()=>{
        if(selectedProvince != "ایران"){
            getCityByProvinceName();
        }
    },[selectedProvince])

    useEffect(()=>{
        setFilterData({})
        setFilters({})
    },[category, subcat, subsubcat])

    useEffect(()=>{
       if(filterData?.c){
           getDistrictById(filterData?.c)
       }
    },[filterData])

    const letSearch = () => {
        const filteredObj = Object.entries(filterData).reduce((a,[k,v]) => (v ? (a[k]=v, a) : a), {})
        setStatus(false)
        setSearchParams(filteredObj);
        setFilters(filteredObj);
    }

    const handleClose = (k) => {
        const { [k]: removedKey, ...rest } = filters;
        setSearchParams(rest);
        setFilters(rest);
        setFilterData(rest);
    }

    const filterToName = (n) => {
        return {
            "b": "برند",
            "m": "مدل",
            "c": "شهر",
            "d": "منطقه",
            "met": "متراژ",
            "ml": "کارکرد",
            "fac": "امکانات",
            "rent": "اجاره",
            "dep": "ودیعه",
            "t": "نوع کالا",
            "cl": "رنگ",
            "y": "سال",
            "s": "نوع آگهی",
            "pr": "نوع قیمت",
            "p": "قیمت",
            "ex": "نوع فروش"
        }[n] || null;
    }

    const items = [
        {
            label: 'پیش فرض',
            key: 1
        },
        {
            label: 'گران ترین',
            key: 2
        },
        {
            label: 'ارزان ترین',
            key: 3
        },
        {
            label: 'پربازدید ترین',
            key: 4
        },
    ]

    const getBrands = async () => {
        setBrandsLoading(true)
        const data = {
            subsubcat: unDasher(subsubcat)
        }
        await getBrandsApi(data).then((r)=>{
            setBrandsLoading(false)

            r.data.filter((x)=>(x.label = x.name, x.value = x.id, x.key = x.id))
            setBrands(r.data)
        }).catch(()=>{

        })
    }

    const getModel = async (id) => {
        setModelsLoading(true)
        await getModelApi({ id: decodeURIComponent(id) }).then((r) => {
            r.data.filter((x)=>(x.label = x.name, x.value = x.id, x.key = x.id))
            setModels(r.data)
            setModelsLoading(false)
        });
    }

    const filterOption = (input, option) =>
        (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

    const getCityByProvinceName = async () => {
        const data = {
            name: unDasher(selectedProvince)
        }
        await getCityByProvinceNameApi(data).then((res)=>{
            res.data.filter((x)=> (x.label = x.title, x.value = x.id));
            setCities(res.data)
        }).catch(()=>{

        })
    }

    const getDistrictById = async (id) => {
        await getDistrictByIdApi(id).then((res)=>{
            res.data.filter((x)=> (x.label = x.title, x.value = x.id));
            setDistricts(res.data)
        }).catch(()=>{

        })
    }

    return (
        <div className={Style.BrowseFilterContainer}>
            <div className="d-flex justify-content-between align-items-center">
                <div className="d-flex align-items-center">
                    <Button
                        onClick={()=>setStatus(true)}
                        icon={<i style={{fontSize: "17px"}} className="las la-sliders-h"></i>}
                        shape="round"
                        type="primary"
                    >فیلتر</Button>
                    <div style={{marginRight: "10px"}}>
                        {Object.entries(filters).map(([k], i)=>(
                            <Tag
                                key={k}
                                onClick={()=>handleClose(k)}
                                closable
                            >{filterToName(k)}</Tag>
                        ))}
                    </div>
                </div>

                <Dropdown menu={{items}}>
                    <Button
                        shape="round"
                        icon={<i style={{fontSize: "17px"}} className="las la-sort-amount-down"></i>}
                    />
                </Dropdown>
            </div>

            <div className={`${status ? `${Style.BrowseFilter} ${Style.BrowseFilterActive}` : Style.BrowseFilter}`}>
                <div className={Style.BrowseFilterWrapper}>
                    <Button size="large" onClick={()=>setStatus(false)}>
                        <i className="las la-times"></i>
                    </Button>

                    {selectedProvince != "ایران" ?
                        <div className={Style.BrowseSide}>
                            <div className={Style.SideTitle}>
                                <i className="las la-map-marked-alt"></i>
                                <h6>موقعیت</h6>
                            </div>
                            <Select
                                getPopupContainer={trigger => trigger.parentNode}
                                allowClear
                                showSearch
                                style={{width: "100%"}}
                                placeholder="شهر"
                                options={cities?.length ? cities : [{label: 'همه', value: ''}]}
                                onChange={(value)=>setFilterData({...filterData, c: value})}

                                loading={!cities?.length}
                                disabled={!cities?.length}
                                filterOption={filterOption}
                            />

                            {districts?.length ?
                                <Select
                                    getPopupContainer={trigger => trigger.parentNode}
                                    allowClear
                                    showSearch
                                    mode="multiple"
                                    style={{width: "100%"}}
                                    placeholder="منطقه"
                                    options={districts?.length ? districts : [{label: 'همه', value: ''}]}
                                    onChange={(value)=>setFilterData({...filterData, d: String(value)})}
                                    filterOption={filterOption}
                                />
                                : null
                            }
                        </div> : null}

                    {brands.length ? <div>
                        <Select
                            loading={brandsLoading}
                            allowClear
                            getPopupContainer={trigger => trigger.parentNode}
                            style={{width: "100%"}}
                            mode="multiple"
                            showSearch
                            options={brands}
                            placeholder="برند"
                            onChange={(value)=>(setFilterData({...filterData, b: String(value)}), getModel(String(value)))}
                            filterOption={filterOption}
                        />
                    </div> : null}

                    {models.length ? <div>
                        <Select
                            loading={modelsLoading}
                            allowClear
                            getPopupContainer={trigger => trigger.parentNode}
                            style={{width: "100%"}}
                            mode="multiple"
                            showSearch
                            options={models}
                            placeholder="مدل"
                            onChange={(value)=>setFilterData({...filterData, m: String(value)})}
                            filterOption={filterOption}
                        />
                    </div> : null}

                    {category_id === 1 && subsubcat_id === 59 ?
                        <div>
                            <h5 className={Style.SideSubTitle}>مدل و امکانات</h5>

                            <div>
                                <h5 className={Style.SideSubTitle}>رنگ</h5>
                                <Select
                                    getPopupContainer={trigger => trigger.parentNode}
                                    allowClear
                                    style={{width: "100%"}}
                                    placeholder="انتخاب کنید"
                                    onChange={(value)=>setFilterData({...filterData, cl: value})}
                                    options={AdsCarColor(false)}
                                />
                            </div>
                            <div>
                                <h5 className={Style.SideSubTitle}>سال تولید</h5>
                                <Select
                                    getPopupContainer={trigger => trigger.parentNode}
                                    allowClear
                                    style={{width: "100%"}}
                                    placeholder="انتخاب کنید"
                                    options={AdsCarYear(false)}
                                    onChange={(value)=>setFilterData({...filterData, y: value})}
                                />
                            </div>
                            <div>
                                <h5 className={Style.SideSubTitle}>کارکرد</h5>
                                <Slider
                                    style={{maxWidth: "100%"}}
                                    reverse
                                    range
                                    min={0}
                                    max={500000}
                                    step={1000}
                                    defaultValue={[500000, 0]}
                                    onChange={(value)=>setFilterData({...filterData, ml: String(value)})}
                                />

                                <div className={Style.BrowseFilterRange}>
                                    <span>{filterData?.ml?.split(",")?.length && filterData?.ml?.split(",")[0] || 0} <ins>کیلومتر</ins></span>
                                    <bdi>تا</bdi>
                                    <span>{filterData?.ml?.split(",")?.length && filterData?.ml?.split(",")[1] || 500000} <ins>کیلومتر</ins></span>
                                </div>

                            </div>
                        </div>
                        :
                        null}

                    {category_id === 3 &&
                    (subcategory_id === 93 || subcategory_id === 94 || subcategory_id === 95) &&
                    (subsubcat_id != 105 && subsubcat_id != 104 && subsubcat_id != 109 && subsubcat_id != 110 && subsubcat_id != 111 && subsubcat_id != 112 && subsubcat_id != 113 && subsubcat_id != 114 && subsubcat_id != 115 && subsubcat_id != 116) ?
                        <div>
                            <h6>مدل و امکانات</h6>


                            <h5 className={Style.SideTitle}>متراژ</h5>
                            <Slider
                                style={{maxWidth: "100%"}}
                                reverse
                                range
                                min={0}
                                max={1000}
                                step={10}
                                defaultValue={[1000, 0]}
                                onChange={(value)=>setFilterData({...filterData, met: String(value)})}
                            />

                            <div className={Style.BrowseFilterRange}>
                                <span>{filterData?.met?.split(",")?.length && filterData?.met?.split(",")[0] || 0} <ins>متر</ins></span>
                                <bdi>تا</bdi>
                                <span>{filterData?.met?.split(",")?.length && filterData?.met?.split(",")[1] || 1000} <ins>متر</ins></span>
                            </div>

                            <h5 className={`${Style.SideSubTitle} mb-3 mt-4`}>امکانات</h5>
                            <Select
                                getPopupContainer={trigger => trigger.parentNode}
                                allowClear
                                mode="multiple"
                                style={{width: "100%"}}
                                placeholder="انتخاب کنید"
                                options={AdsEstateOptions()}
                                onChange={(value)=>setFilterData({...filterData, fac: String(value)})}
                            />
                        </div> : null}

                    <div>
                        <h5 className={Style.SideSubTitle}>نوع کالا</h5>
                        <div className="d-flex justify-content-center align-items-center">
                            <Radio.Group
                                style={{ width: "100%" }}
                                buttonStyle="solid"
                                value={filterData?.t ? String(filterData.t) : ""}
                                onChange={(e)=>setFilterData({...filterData, t: e.target.value})}

                            >
                                <Radio.Button value="">همه</Radio.Button>
                                <Radio.Button value="1">نو</Radio.Button>
                                <Radio.Button value="2">کارکرده</Radio.Button>
                                <Radio.Button value="3">خدماتی</Radio.Button>
                            </Radio.Group>
                        </div>
                    </div>

                    <div>
                        <h5 className={Style.SideSubTitle}>نوع قیمت</h5>
                        <div className="d-flex justify-content-center align-items-center">
                            <Radio.Group
                                value={filterData?.p ? String(filterData.p) : ""}
                                onChange={(e)=>setFilterData({...filterData, p: e.target.value})}
                                buttonStyle="solid"
                            >
                                <Radio.Button value="">همه</Radio.Button>
                                <Radio.Button value="2">با قیمت</Radio.Button>
                                <Radio.Button value="1">توافقی</Radio.Button>
                            </Radio.Group>
                        </div>
                    </div>

                    <div className={Style.BrowseFilterButton}>
                        <Button
                            style={{
                                width: '100%',
                            }}
                            type="primary"
                            size="large"
                            shape="round"
                            onClick={()=>letSearch()}
                        >جستجو</Button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Filter;