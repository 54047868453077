import {
    SET_LOADING,
    SET_DEFAULT_PROVINCE
} from "../constants/user";

export const setLoading = x => ({
    type: SET_LOADING,
    payload: x
});

export const setDefaultProvince = x => ({
    type: SET_DEFAULT_PROVINCE,
    payload: x
});