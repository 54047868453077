import Style from "../assets/styles/home.module.css";
import {Link} from "react-router-dom";
import {urlDasher} from "../api/helper";
import {useSelector} from "react-redux";

const Categories = ({home, province, category, subcat, subsubcat,
                        categories, subCategories, subSubCategories}) => {

    const selectedProvince = useSelector((state)=>state?.user?.province) || "ایران";

    return (
        home || province ||category || subcat ?
            <ul className={`${Style.HomeCategories} list-unstyled d-flex`}>
                {subsubcat ? null :
                    subcat ? subSubCategories?.map((SubSubCat, Index)=>{
                            return (
                                <li key={Index}>
                                    <Link to={`/browse/${urlDasher(selectedProvince)}/${urlDasher(category)}/${urlDasher(subcat)}/${urlDasher(SubSubCat.name)}`}>
                                        <i className="icon" data-subsub-cat-id={SubSubCat.id}></i>
                                        <span>{SubSubCat.name}</span>
                                    </Link>
                                </li>
                            )
                        }) :
                        category ? subCategories?.map((SubCat, Index)=>{
                                return (
                                    <li key={Index}>
                                        <Link to={`/browse/${urlDasher(selectedProvince)}/${urlDasher(category)}/${urlDasher(SubCat.name)}`}>
                                            <i className="icon" data-sub-cat-id={SubCat.id}></i>
                                            <span>{SubCat.name}</span>
                                        </Link>
                                    </li>
                                )
                            }) :
                            province || home ? categories?.map((Category, Index)=>{
                                return (
                                    <li key={Index}>
                                        <Link to={`/browse/${selectedProvince}/${urlDasher(Category.name)}`}>
                                            <i className="icon" data-cat-id={Category.id}></i>
                                            <span>{Category.name}</span>
                                        </Link>
                                    </li>
                                )
                            }) : null
                }
            </ul>
            : null
    )
}

export default Categories;