import {
    SET_DEFAULT_PROVINCE,
    SET_LOADING
} from '../constants/user';

const initState = {
    loading: true,
    province: null
}

const User = (state = initState, action) => {
    switch (action.type) {
        case SET_LOADING:
            return {
                ...state,
                loading: action.payload
            }
        case SET_DEFAULT_PROVINCE:
            return {
                ...state,
                province: action.payload
            }
        default:
            return state;
    }
}

export default User