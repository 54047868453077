import {post, get, put, remove} from "./curd";
import {
    get_category,
    get_ad_by_id,
    get_app_ads,
    get_ads_search,
    get_ads,
    get_province,
    get_sub_category,
    get_sub_sub_by_subcat,
    get_brand_by_sub_sub,
    get_model,
    get_sub_category_id, get_sub_subcat, get_city_by_province, get_district_by_city
} from "./routes";

export const getCategoryApi = () => {
    return get(get_category);
}

export const getSubCategoryByNameApi = (data) => {
    let url = get_sub_category;
    if (data) { url += `?${Object.keys(data).map(k => `${encodeURIComponent(k)}=${encodeURIComponent(data[k])}`).join('&')}` }
    return get(url);
}

export const getSubSubCatByNameApi = (data) => {
    let url = get_sub_sub_by_subcat;
    if (data) { url += `?${Object.keys(data).map(k => `${encodeURIComponent(k)}=${encodeURIComponent(data[k])}`).join('&')}` }
    return get(url);
}

export const getAppAdsApi = (p) => {
    let url = get_app_ads;
    if(p){
        url = `${get_app_ads}?page=${p}`
    }
    return get(url);
}

export const getAdByIdApi = (id) => {
    return get(get_ad_by_id(id));
}

export const getAdsSearchApi = (data) => {
    let url = get_ads_search;
    if (data) { url += `?${Object.keys(data).map(k => `${encodeURIComponent(k)}=${encodeURIComponent(data[k])}`).join('&')}` }
    return get( url);
}

export const getAdsApi = (data) => {
    let url = get_ads;
    if (data) { url += `?${Object.keys(data).map(k => `${encodeURIComponent(k)}=${encodeURIComponent(data[k])}`).join('&')}` }
    return get(url);
}

export const getProvinceApi = () => {
    let url = get_province;
    return get(url);
}

export const getCityByProvinceNameApi = (data) => {
    let url = get_city_by_province;
    if (data) { url += `?${Object.keys(data).map(k => `${encodeURIComponent(k)}=${encodeURIComponent(data[k])}`).join('&')}` }
    return get(url);
}

export const getDistrictByIdApi = (id) => {
    let url = get_district_by_city(id);
    return get(url);
}

export const getBrandsApi = (data) => {
    let url = get_brand_by_sub_sub;
    if (data) { url += `?${Object.keys(data).map(k => `${encodeURIComponent(k)}=${encodeURIComponent(data[k])}`).join('&')}` }
    return get(url);
}

export const getModelApi = (data) => {
    let url = get_model;
    if (data) { url += `?${Object.keys(data).map(k => `${encodeURIComponent(k)}=${encodeURIComponent(data[k])}`).join('&')}` }
    return get(url);
}

export const getSubCategoryApi = (id) => {
    let url = get_sub_category_id(id);
    return get(url);
}

export const getSubSubCategoryApi = (id) => {
    let url = get_sub_subcat(id);
    return get(url);
}