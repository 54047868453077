import {Link, useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {getAdByIdApi} from "../../api/api";
import {appUrl, Root} from "../../api/setting";
import {Button, Carousel, Image, message, Spin} from 'antd';
import Style from "../../assets/styles/ads.module.scss";
import HomeLayout from "../../template/home";
import {urlDasher, formatPrice, AdsExchange, AdsPrice, AdsType, AdsEstateOptions, AdsCarColor, AdsCarYear} from "../../api/helper";
import Moment from "react-moment";
import 'moment/locale/fa';

const ViewAds = () => {

    const Params = useParams();
    const {ads_id} = Params;
    const [ad, setAd] = useState({});
    const [loading, setLoading] = useState(false);
    const [revealTel, setRevealTel] = useState(false);

    useEffect(()=>{
        if(ads_id){
            getAdById(ads_id)
        }
    },[Params]);

    const getAdById = async (id) => {
        setLoading(true)
        await getAdByIdApi(id).then((res)=>{
            setAd(res.data)
            setLoading(false)
        }).catch(()=>{

        })
    }

    const handleCopy = (v) => {
        message.success("با موفقیت کپی شد!")
        navigator.clipboard.writeText(v)
    }

    return (
        <HomeLayout>
            <div className={Style.AdsImage}>
                <div className={Style.AdsTools}>
                    <ul className="list-unstyled m-0 p-0 d-flex align-items-center justify-content-center">
                        <li onClick={()=>handleCopy(`${appUrl}/v/${ads_id}`)}><i className="las la-link"></i></li>
                        <li><i className="las la-bookmark"></i></li>
                    </ul>
                </div>
                <Carousel>
                    {Array.from({ length: 5 }).map((_, index) => {
                        const imgSrc = `${Root}/seller/uploads/${ad.user}/${ad.adsid}/${ad[`img${index + 1}`]}-resized.jpg`;
                        return ad[`img${index + 1}`] ? (
                            <div className={Style.AdImage} key={index}>
                                <Image style={{ width: "100%", height: "100%" }} src={imgSrc} />
                            </div>
                        ) : null;
                    })}
                </Carousel>
            </div>

            <div className={Style.AdsViewContainer}>
                {loading ?
                    <div className="d-flex align-items-center justify-content-center">
                        <Spin/>
                    </div>
                     :
                    <>
                        <div className="d-flex align-items-start justify-content-between">
                            <div className="d-flex align-items-center flex-wrap" style={{flex: "1"}}>
                                <h1>{ad.title}</h1>
                                <div className={`${Style.AdsType} ${Style.AdsTypeView} ${ad?.type === 1 ? Style.AdsTypeNew : ad?.type === 2 ? Style.AdsTypeUsed : ad?.type === 3 ? Style.AdsTypeService : null}`}>
                                    {AdsType(ad?.type)?.label}
                                </div>
                            </div>

                            <div className={Style.AdsDate}>
                                <span><i className="las la-clock"></i></span>
                                <time><Moment locale={'fa'} fromNow>{ad?.updated_at ? ad?.updated_at : null}</Moment></time>
                            </div>
                        </div>

                        <div className={Style.AdsPriceView}>
                            <div>
                                <span>
                                <i className="las la-dollar-sign"></i>
                            </span>
                                <strong>{AdsPrice(ad?.price, ad?.pricetype)}</strong>
                            </div>
                            {ad?.type != 3 ?
                            <div className={Style.AdsSellType}>
                                <i className="las la-exchange-alt"></i>
                                <span>{AdsExchange(ad?.exchange)}</span>
                            </div> : null}
                        </div>

                        <div className={Style.AdsTopData}>
                            <div>
                                <div className={Style.AdsCategory}>
                                    <span><i className="icon" data-cat-id={ad?.category?.id}></i></span>
                                    <ul className="list-unstyled d-flex">
                                        <li>
                                            <Link to={`/browse/${urlDasher(ad?.province?.title)}/${urlDasher(ad?.category?.name)}`}>
                                                <span>{ad?.category?.name}</span>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to={`/browse/${urlDasher(ad?.province?.title)}/${urlDasher(ad?.category?.name)}/${urlDasher(ad?.sub_category?.name)}`}>
                                                <span>{`, ${ad?.sub_category?.name}`}</span>
                                            </Link>
                                        </li>
                                        {ad?.sub_sub_cat ?
                                            <li>
                                                <Link to={`/browse/${urlDasher(ad?.province?.title)}/${urlDasher(ad?.category?.name)}/${urlDasher(ad?.sub_category?.name)}/${urlDasher(ad?.sub_sub_cat?.name)}`}>
                                                    <span>{`, ${ad?.sub_sub_cat?.name}`}</span>
                                                </Link>
                                            </li>
                                            : null
                                        }
                                        {ad?.brand ?
                                            <li>
                                                <Link to={`/browse/${urlDasher(ad?.province?.title)}/${urlDasher(ad?.category?.name)}/${urlDasher(ad?.sub_category?.name)}/${urlDasher(ad?.sub_sub_cat?.name)}?b=${ad?.brand?.id}`}>
                                                    <span>{`, ${ad?.brand?.name}`}</span>
                                                </Link>
                                            </li> : null
                                        }
                                        {ad?.model ?
                                            <li>
                                                <Link to={`/browse/${urlDasher(ad?.province?.title)}/${urlDasher(ad?.category?.name)}/${urlDasher(ad?.sub_category?.name)}/${urlDasher(ad?.sub_sub_cat?.name)}?b=${ad?.brand?.id}&m=${ad?.model?.id}`}>
                                                    <span>{`, ${ad?.model?.name}`}</span>
                                                </Link>
                                            </li> : null
                                        }
                                    </ul>
                                </div>
                                <div className={Style.AdsLoc}>
                                    <span><i className="las la-map-marker"></i></span>
                                    <ul className="list-unstyled d-flex">
                                        <li><Link to={`/browse/${urlDasher(ad?.province?.title)}`}>{ad?.province?.title}</Link></li>
                                        <li><Link to={`/browse/${urlDasher(ad?.province?.title)}?c=${ad?.city?.id}`}>{`, ${ad?.city?.title}`}</Link></li>
                                        <li><Link to={`/browse/${urlDasher(ad?.province?.title)}?c=${ad?.city?.id}&d=${ad?.district?.id}`}>{ad?.district?.dis > 0 ? `, ${ad?.district?.title} (منطقه ${ad?.district?.dis})` : null}</Link></li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className={Style.AdsDesc}>
                            <p>{ad.description}</p>
                        </div>

                        <div className={Style.AdsFeatures}>
                            {ad?.options?.estate_price_m ?
                                <div>
                                    <span>قیمت هر متر مربع:</span>
                                    <strong>{formatPrice(ad?.options?.estate_price_m)} <span>تومان</span></strong>
                                </div> : null
                            }
                            {ad?.options?.estate_price_rent ?
                                <div>
                                    <span>اجاره:</span>
                                    <strong>{formatPrice(ad?.options?.estate_price_rent)} <span>تومان</span></strong>
                                </div> : null
                            }
                        </div>

                        {ad?.options ?
                            <div className={Style.AdsFeatures}>
                                {ad?.cat === 1 && ad?.subsubcat === 59 ?
                                    <>
                                        <ul className={`${Style.ViewAdsAdditional} list-unstyled m-0 p-0`}>
                                            {ad?.options?.car_color ? <li><i className="las la-brush"></i><span>رنگ:</span><bdi>{AdsCarColor(true, ad?.options?.car_color).label}</bdi><span className={"color-range"} style={{"background": `${AdsCarColor(true, ad?.options?.car_color).color}`}}></span></li> : null}
                                            {ad?.options?.car_mileage ? <li><i className="las la-tachometer-alt"></i><span>کارکرد:</span><bdi>{ad?.options?.car_mileage} کیلومتر</bdi></li> : null}
                                            {ad?.options?.car_year ? <li><i className="las la-calendar"></i><span>سال ساخت:</span><bdi>{AdsCarYear(true, ad?.options?.car_year).label}</bdi></li> : null}
                                        </ul>
                                    </>
                                    : null
                                }
                                {ad?.cat === 3 && (ad?.subcat === 93 || ad?.subcat === 94 || ad?.subcat === 95) && (ad?.subsubcat != 105 && ad?.subsubcat != 104 && ad?.subsubcat != 109 && ad?.subsubcat != 110 && ad?.subsubcat != 111 && ad?.subsubcat != 112 && ad?.subsubcat != 113 && ad?.subsubcat != 114 && ad?.subsubcat != 115 && ad?.subsubcat != 116) ?
                                    <div>
                                        <h6>مشخصات:</h6>
                                        <ul className={`${Style.ViewAdsAdditional} list-unstyled d-flex`}>
                                            {ad?.options?.estate_age ? <li><i className="las la-industry"></i><span>سال ساخت:</span><bdi>{ad?.options?.estate_age > 0 ? `${ad?.options?.estate_age} سال` : `نوساز`}</bdi></li> : null}
                                            {ad?.options?.estate_area ? <li><i className="las la-ruler-combined"></i><span>متراژ:</span><bdi>{ad?.options?.estate_area} متر</bdi></li> : null}
                                            {ad?.options?.estate_bed ? <li><i className="las la-door-closed"></i><bdi>{ad?.options?.estate_bed} اتاق</bdi></li> : null}
                                            {ad?.options?.estate_floor ? <li><i className="las la-city"></i><span>طبقه: </span><bdi>{ad?.options?.estate_floor}</bdi></li> : null}
                                        </ul>

                                        <h6>امکانات:</h6>
                                        <div className="d-flex">
                                            {ad?.options?.estate_option.split(",").map((value, index) => {
                                                let option = AdsEstateOptions.find(opt => opt.value === value.trim());

                                                if (option) {
                                                    return (
                                                        <div key={index}>
                                                            <span>{option.label}</span>
                                                            <i className={option.icon}></i>
                                                        </div>
                                                    );
                                                }

                                                return null;
                                            })}
                                        </div>
                                    </div>
                                    : null
                                }
                            </div>
                            : null
                        }
                    </>
                }
            </div>
            {!loading ? <Link
                className={Style.AdsCall}
                onClick={()=>(setRevealTel(true), handleCopy(ad?.seller?.tel))}
                to={`tel:${ad?.seller?.tel}`}>
                <i className="las la-phone"></i>
                <span>{revealTel ? ad?.seller?.tel: "تماس با فروشنده"}</span>
            </Link> : null}
        </HomeLayout>
    )
}

export default ViewAds;