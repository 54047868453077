// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.search_HeaderSearch__EB\\+U2 {
    position: relative;
}

.search_HeaderSearch__EB\\+U2 input,
.search_HeaderSearch__EB\\+U2 input:focus,
.search_HeaderSearch__EB\\+U2 input:focus-visible {
    background: var(--color-red);
    border: 1px solid rgba(255, 255, 255, 0.2);
    min-height: 40px;
    border-radius: 8px;
    margin: 10px 0 15px 0;
    outline: unset;
    color: white;
    font-family: IranSans;
    padding: 0 10px;
    width: 100%;
}

.search_HeaderSearch__EB\\+U2 input::placeholder {
    color: rgba(255, 255, 255, 0.8);
}

.search_LiveSearch__oA9ne {
    position: absolute;
    background: rgb(255 255 255 / 90%);
    border: 1px solid #ff6666;
    padding: 15px;
    border-radius: 5px;
    opacity: 0;
    top: 10px;
    transition: .2s all ease;
    z-index: 1;
    transform: scale(0.9);
    -webkit-backdrop-filter: blur(3px);
            backdrop-filter: blur(3px);
    visibility: hidden;
    left: 0;
    right: 0;
}

.search_LiveSearchActive__IP6lI {
    visibility: visible;
    opacity: 1;
    top: 50px;
    transform: scale(1.0);
}`, "",{"version":3,"sources":["webpack://./src/assets/styles/search.module.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;AACtB;;AAEA;;;IAGI,4BAA4B;IAC5B,0CAA0C;IAC1C,gBAAgB;IAChB,kBAAkB;IAClB,qBAAqB;IACrB,cAAc;IACd,YAAY;IACZ,qBAAqB;IACrB,eAAe;IACf,WAAW;AACf;;AAEA;IACI,+BAA+B;AACnC;;AAEA;IACI,kBAAkB;IAClB,kCAAkC;IAClC,yBAAyB;IACzB,aAAa;IACb,kBAAkB;IAClB,UAAU;IACV,SAAS;IACT,wBAAwB;IACxB,UAAU;IACV,qBAAqB;IACrB,kCAA0B;YAA1B,0BAA0B;IAC1B,kBAAkB;IAClB,OAAO;IACP,QAAQ;AACZ;;AAEA;IACI,mBAAmB;IACnB,UAAU;IACV,SAAS;IACT,qBAAqB;AACzB","sourcesContent":[".HeaderSearch {\r\n    position: relative;\r\n}\r\n\r\n.HeaderSearch input,\r\n.HeaderSearch input:focus,\r\n.HeaderSearch input:focus-visible {\r\n    background: var(--color-red);\r\n    border: 1px solid rgba(255, 255, 255, 0.2);\r\n    min-height: 40px;\r\n    border-radius: 8px;\r\n    margin: 10px 0 15px 0;\r\n    outline: unset;\r\n    color: white;\r\n    font-family: IranSans;\r\n    padding: 0 10px;\r\n    width: 100%;\r\n}\r\n\r\n.HeaderSearch input::placeholder {\r\n    color: rgba(255, 255, 255, 0.8);\r\n}\r\n\r\n.LiveSearch {\r\n    position: absolute;\r\n    background: rgb(255 255 255 / 90%);\r\n    border: 1px solid #ff6666;\r\n    padding: 15px;\r\n    border-radius: 5px;\r\n    opacity: 0;\r\n    top: 10px;\r\n    transition: .2s all ease;\r\n    z-index: 1;\r\n    transform: scale(0.9);\r\n    backdrop-filter: blur(3px);\r\n    visibility: hidden;\r\n    left: 0;\r\n    right: 0;\r\n}\r\n\r\n.LiveSearchActive {\r\n    visibility: visible;\r\n    opacity: 1;\r\n    top: 50px;\r\n    transform: scale(1.0);\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"HeaderSearch": `search_HeaderSearch__EB+U2`,
	"LiveSearch": `search_LiveSearch__oA9ne`,
	"LiveSearchActive": `search_LiveSearchActive__IP6lI`
};
export default ___CSS_LOADER_EXPORT___;
