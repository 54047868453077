export const get_category = "category/index";
export const get_sub_category = "category/sub";
export const get_sub_category_id = (id) => `category/sub/${id}`;
export const get_sub_sub_by_subcat = "subcat/sscat";
export const get_app_ads = "app/ads";
export const get_ad_by_id = (id) => `ads/index/${id}`;
export const get_ads_by_cat = (id) => `ads/cat/${id}`;
export const get_ads_search = `ads/search`;
export const get_ads = "ads";
export const get_province = "province/index";
export const get_city_by_province = "city/province";
export const get_district_by_city = (id) => `district/city/${id}`;
export const get_brand_by_sub_sub = "sscat/brand";
export const get_model = "brand/model";
export const get_sub_subcat = (id) => `subcat/sub/${id}`;
