import {Route, Routes} from "react-router-dom";
import Home from "./pages/home";
import ViewAds from "./pages/ads/[ads_id]";
import BrowseAds from "./pages/browse";

function App({pageProps}) {
  return (
      <Routes {...pageProps}>
        <Route path={"/"} element={<Home/>} />
        <Route path={"/view/:ads_id"} element={<ViewAds/>} />
        <Route path={"/browse/:province"} element={<BrowseAds/>} />
        <Route path={"/browse/:province/:category"} element={<BrowseAds/>} />
        <Route path={"/browse/:province/:category/:subcat"} element={<BrowseAds/>} />
        <Route path={"/browse/:province/:category/:subcat/:subsubcat"} element={<BrowseAds/>} />
      </Routes>
  );
}

export default App;
