import {useEffect, useRef, useState} from "react";
import Style from '../assets/styles/search.module.css';
import infinity from "../assets/images/rings.svg";
import {getAdsSearchApi} from "../api/api";
import {Root} from "../api/setting";
import default_img from "../assets/images/default.png";
import {useLocation} from "react-router-dom";
import {Input, Row, Col} from "antd";
import {Link} from "react-router-dom";
import {urlDasher, urlPluser} from "../api/helper";

const Search = () => {

    const router = useLocation();
    const [viewLiveSearch, setViewLiveSearch] = useState(false);
    const [loader, setLoader] = useState(false);
    const [noResult, setnoResult] = useState(false);
    const [search, setSearch] = useState("");
    const [searchResult, setSearchResult] = useState([]);
    const input = useRef(null);
    const searchRes = useRef(null);
    const useOutsideAlerter = () => {
        useEffect(() => {
            const handleClickOutside = (e) => {
                if (searchRes.current && !searchRes.current.contains(e.target)) {
                    closeLiveSearch();
                }
            }
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                document.removeEventListener("mousedown", handleClickOutside);
            }
        }, [searchRes]);
    }

    useOutsideAlerter(searchRes);

    const closeLiveSearch = () => {
        setViewLiveSearch(false)
        setSearch("")
    }

    const handleGetSearch = (v) => {

        if(v.length){
            setViewLiveSearch(true)
            setSearch(v)
            setLoader(true)
            setnoResult(false)
        } else {
            setViewLiveSearch(false)
            setSearch("")
        }

        setSearchResult([])
    }

    const doSearch = () => {
        if(search && search.length >= 3){
            const data = {
                q: search,
                limit: 10
            }
            getAdsSearchApi(data).then((r)=>{
                if(r.data.length){
                    setSearchResult(r.data)
                    setnoResult(false)
                } else {
                    setnoResult(true)
                    setSearchResult([])
                }

                setLoader(false)
            }).catch(()=>{

            })
        } else {
            setLoader(false)
            setnoResult(true)
        }
    }

    const handleEnter = (e) => {
        const key = e.which || e.key;
        const keyword = e.target.value;

        if(key === 13 || key === "enter"){
            closeLiveSearch();
            //router(`/search?q=${Scripts.urlPluser(keyword)}`)
        }
    }

    return (
        <div ref={searchRes} className={`${Style.HeaderSearch}`}>
            <Input
                onKeyPress={(e)=>handleEnter(e)}
                value={search}
                onKeyUp={()=>doSearch()}
                onInput={(e)=>handleGetSearch(e.target.value)}
                placeholder="جستجو در نوبازار..!"
            />

            {viewLiveSearch ?
                <span className={Style.CloseLiveSearch} onClick={()=>closeLiveSearch()}>
                <i className="las la-times"></i>
            </span> : null}

            <div className={`${viewLiveSearch ? `${Style.LiveSearch} ${Style.LiveSearchActive}` : `${Style.LiveSearch}`}`}>
                <span><i className="las la-search"></i></span>
                <span>جستجو برای: <strong>{search}</strong></span>

                <Row className={Style.SearchResult}>
                    {searchResult?.map((Item, Index)=>{
                        return (
                            <Col key={Index} span={24}>
                                <div className={Style.SearchResults}>
                                    <Link className="d-flex" href={'/view/' + Item?.id + '/' + urlDasher(Item.title)}>
                                        {Item?.img1 ?
                                            <div className={Style.SearchResultImg}><img alt={Item.title} width={50} height={50} src={Root + '/seller/uploads/' + Item?.user + '/' + Item?.adsid + '/' + Item?.img1 + '-thumb.jpg'}/></div>
                                            :
                                            <div className={Style.SearchResultImgDefault}><img alt={Item.title} width={40} height={40} src={default_img}/></div>}
                                        <span className={Style.SearchResultTitle}>{Item.title}</span>
                                    </Link>
                                </div>
                            </Col>
                        )
                    })}
                </Row>

                {searchResult.length ?
                    <div>
                        <Link onClick={()=>closeLiveSearch()} to={`/search?q=${urlPluser(search)}`}>
                            <span>جستجوی بیشتر</span>
                            <i className="las la-arrow-left"></i>
                        </Link>
                    </div>
                    : null}

                {loader ?
                    <div>
                        <img src={infinity}/>
                    </div> : null}

                {noResult ? <div>
                    <span><i className="las la-exclamation-circle"></i></span>
                    <span>متاسفانه هیچ آگهی مرتبط به درخواست شما یافت نشد!</span>
                </div> : null}
            </div>
        </div>
    )
}

export default Search;