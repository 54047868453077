import axios from "axios";
import {BaseApiUrl} from "./setting";

const Axios = axios.create({
    baseURL: BaseApiUrl,
    headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
        "x-header": "ZnVjayBldmVyeW9uZSB3aG8gbWFrZXMgeW91IGZlZWwgbGlrZSB5b3UgYXJlbid0IGdvb2QgZW5vdWdo"
    }
});

export const get = (url, config) => {
    return Axios.get(url, config).then((result)=>{
        return result;
    }).catch((error) => {
        if(error?.response?.status === 401){
            window.location.href = "/login";
        } else {
            return Promise.reject(error)
        }
    });
}


export const post = (url, data, config) => {
    return Axios.post(url, data, config).then((result)=>{
        return result;
    }).catch((error) => {
        if(error?.response?.status === 401){
            window.location.href = "/login";
        } else {
            return Promise.reject(error)
        }
    });
}

export const put = (url, data, config) => {
    return Axios.put(url, data, config).then((result)=>{
        return result;
    }).catch((error) => {
        if(error?.response?.status === 401){
            window.location.href = "/login";
        } else {
            return Promise.reject(error)
        }
    });
}

export const remove = (url, config) => {
    return Axios.delete(url, config).then((result)=>{
        return result;
    }).catch((error) => {
        if(error?.response?.status === 401){
            window.location.href = "/login";
        } else {
            return Promise.reject(error)
        }
    });
}