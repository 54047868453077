import Style from "../assets/styles/header.module.css";
import Loader from "../assets/images/loading.gif";
import {useSelector} from "react-redux";

const Loading = () => {
    const status = useSelector((state)=>state?.user?.loading);

    return (
        <>
            {status ? <div className={Style.Loading}>
                <img width={150} height={150} src={Loader}/>
            </div> : null}
        </>
    )
}

export default Loading;